import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

type Props = {
  color?: 'black' | 'white';
  size?: 'desktop' | 'mobile';
};

const LogoImage: React.FC<Props> = ({ color, size }: Props) => {
  const data = useStaticQuery(graphql`
    query LogoImage {
      homeBannerWhite: file(relativePath: { eq: "home-banner-white.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 274)
        }
      }
      homeBannerWhiteMobile: file(
        relativePath: { eq: "home-banner-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 200)
        }
      }
      headerBlack: file(relativePath: { eq: "header-black.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 200)
        }
      }
      #      rlpHomeBannerWhite: file(relativePath: { eq: "rlp-banner-white.png" }) {
      #        childImageSharp {
      #          gatsbyImageData(layout: FIXED, width: 390)
      #        }
      #      }
      rlpHomeBannerWhite: file(
        relativePath: { eq: "rlp-banner-white-alt.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 566)
        }
      }
      rlpHomeBannerWhiteMobile: file(
        relativePath: { eq: "rlp-banner-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 300)
        }
      }
    }
  `);

  if (color === 'white') {
    if (process.env.GATSBY_DOMAIN_ID === 'rlp') {
      return (
        <div
          className="relative"
          style={{
            // height: size === 'mobile' ? 58 : 114,
            // width: size === 'mobile' ? 300 : 390,
            height: size === 'mobile' ? 32 : 64,
            width: size === 'mobile' ? 283 : 566,
            top: -15,
          }}
        >
          <GatsbyImage
            alt="Uhuru Solidarity"
            image={
              size === 'mobile'
                ? data.rlpHomeBannerWhiteMobile.childImageSharp.gatsbyImageData
                : data.rlpHomeBannerWhite.childImageSharp.gatsbyImageData
            }
          />
        </div>
      );
    }
    return (
      <div
        style={{
          height: size === 'mobile' ? 27 : 37,
          width: size === 'mobile' ? 200 : 274,
        }}
      >
        <GatsbyImage
          alt="Uhuru Solidarity"
          image={
            size === 'mobile'
              ? data.homeBannerWhiteMobile.childImageSharp.gatsbyImageData
              : data.homeBannerWhite.childImageSharp.gatsbyImageData
          }
        />
      </div>
    );
  }

  return (
    <div style={{ height: 27 }}>
      <Link to="/" style={{ height: 27, width: 200 }}>
        <GatsbyImage
          alt="Uhuru Solidarity"
          image={data.headerBlack.childImageSharp.gatsbyImageData}
        />
      </Link>
    </div>
  );
};

LogoImage.defaultProps = {
  color: 'black',
  size: 'desktop',
};

export default LogoImage;
