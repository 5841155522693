import React from 'react';

type HeaderProps = React.PropsWithChildren<{
  color?: 'white' | 'black';
  mobileColorsFlipped?: boolean;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span';
  className?: string;
}>;

const Header: React.FC<HeaderProps> = ({
  color,
  mobileColorsFlipped,
  tag,
  className,
  children,
}: HeaderProps) => {
  let colorClass;
  if (color === 'black') {
    colorClass = 'text-black';
    if (mobileColorsFlipped) {
      colorClass = 'text-white md:text-black';
    }
  } else if (color === 'white') {
    colorClass = 'text-white';
    if (mobileColorsFlipped) {
      colorClass = 'text-black md:text-white';
    }
  }

  const tailwindsClasses =
    colorClass || className
      ? `${colorClass || ''}${colorClass && className ? ' ' : ''}${
          className || ''
        }`
      : undefined;

  switch (tag) {
    case 'h1':
      return <h1 className={tailwindsClasses}>{children}</h1>;
    case 'h3':
      return <h3 className={tailwindsClasses}>{children}</h3>;
    case 'h4':
      return <h4 className={tailwindsClasses}>{children}</h4>;
    case 'h5':
      return <h5 className={tailwindsClasses}>{children}</h5>;
    case 'p':
      return <p className={tailwindsClasses}>{children}</p>;
    case 'span':
      return <span className={tailwindsClasses}>{children}</span>;
    default:
      return <h2 className={tailwindsClasses}>{children}</h2>;
  }
};

export const Header1: React.FC<HeaderProps> = ({
  color,
  mobileColorsFlipped,
  tag,
  className,
  children,
}: HeaderProps) => {
  const tailwindsClasses = `font-condensed font-black text-3xl md:text-5xl ${
    className || ''
  }`;

  return (
    <Header
      color={color}
      mobileColorsFlipped={mobileColorsFlipped}
      tag={tag}
      className={tailwindsClasses}
    >
      {children}
    </Header>
  );
};

export const Header2: React.FC<HeaderProps> = ({
  color,
  mobileColorsFlipped,
  tag,
  className,
  children,
}: HeaderProps) => {
  const tailwindsClasses = `font-condensed font-black text-3xl md:text-2xl lg:text-3xl ${
    className || ''
  }`;

  return (
    <Header
      color={color}
      mobileColorsFlipped={mobileColorsFlipped}
      tag={tag}
      className={tailwindsClasses}
    >
      {children}
    </Header>
  );
};

export const Header3: React.FC<HeaderProps> = ({
  color,
  mobileColorsFlipped,
  tag,
  className,
  children,
}: HeaderProps) => {
  const tailwindsClasses = `md:font-condensed md:font-black text-md md:text-lg ${
    className || ''
  }`;

  return (
    <Header
      color={color}
      mobileColorsFlipped={mobileColorsFlipped}
      tag={tag}
      className={tailwindsClasses}
    >
      {children}
    </Header>
  );
};

export default { Header1, Header2, Header3 };
