import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Header1, Header2 } from './headers';
import { getBaseBgColor, getBaseTextColor } from '../utils';

type LinkProps = React.PropsWithChildren<{
  link: string;
  text?: string;
  className?: string;
  linkClassName?: string;
  flippedHover?: boolean;
}>;

const LinkComponent: React.FC<LinkProps> = ({
  text,
  link,
  className,
  linkClassName,
  flippedHover,
  children,
}: LinkProps) => {
  const isInternalLink = link.startsWith('/');
  const isSubdomainLink =
    link.startsWith('https://rlp.uhurusolidarity.org') ||
    link.startsWith('https://uhurusolidarity.org');

  const childComponent = children || text;

  if (!childComponent) {
    return null;
  }

  let linkClass = linkClassName;
  if (flippedHover) {
    if (linkClass) {
      linkClass += ' flipped-hover';
    } else {
      linkClass = 'flipped-hover';
    }
  }

  return (
    <div className={`${className || ''} cursor-pointer`}>
      {isInternalLink && (
        <GatsbyLink to={link} className={linkClass}>
          {childComponent}
        </GatsbyLink>
      )}
      {!isInternalLink && isSubdomainLink && (
        <a href={link} className={linkClass}>
          {childComponent}
        </a>
      )}
      {!isInternalLink && !isSubdomainLink && (
        <a target="_blank" rel="noreferrer" href={link} className={linkClass}>
          {childComponent}
        </a>
      )}
    </div>
  );
};

export const CTALink: React.FC<LinkProps> = ({
  text,
  link,
  children,
}: LinkProps) => {
  const textClass = getBaseTextColor();
  return (
    <div className={`cursor-pointer ${textClass} font-compressed uppercase`}>
      <LinkComponent text={text} link={link}>
        {children}
      </LinkComponent>
    </div>
  );
};

type ButtonLoadingProps = {
  small?: boolean;
};

export const ButtonLoading: React.FC<ButtonLoadingProps> = ({ small }) => {
  let sizeClasses = 'w-10 h-10';
  if (small) {
    sizeClasses = 'w-4 h-4';
  }
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div
        style={{ borderTopColor: 'transparent' }}
        className={`${sizeClasses} border-4 border-white border-solid rounded-full animate-spin`}
      />
    </div>
  );
};

ButtonLoading.defaultProps = {
  small: false,
};

type CTAButtonProps = React.PropsWithChildren<{
  fixedHeight?: number;
  link?: string;
  text?: string;
  className?: string;
  onToggle?: () => void;
}>;

export const CTAButton: React.FC<CTAButtonProps> = ({
  text,
  link,
  onToggle,
  className,
  fixedHeight,
  children,
}: CTAButtonProps) => {
  const backgroundColorClass = getBaseBgColor();

  const tailwindClasses = `${backgroundColorClass} ${className || ''} px-4`;
  const childComponent = children || text;

  let innerComponentClasses = 'uppercase text-center w-full';

  if (typeof fixedHeight === 'number') {
    innerComponentClasses += ' flex items-center justify-center';
  } else {
    innerComponentClasses += ' py-6';
  }

  if (!childComponent) {
    return null;
  }

  const ctaButtonInnerComponent = (
    <div
      className={innerComponentClasses}
      style={
        typeof fixedHeight === 'number' ? { height: fixedHeight } : undefined
      }
    >
      <Header2 color="white" className="hidden md:block">
        {childComponent}
      </Header2>
      <Header1 color="white" className="block md:hidden">
        {childComponent}
      </Header1>
    </div>
  );

  return (
    <div className={tailwindClasses}>
      {link && (
        <LinkComponent link={link}>{ctaButtonInnerComponent}</LinkComponent>
      )}
      {!link && onToggle && (
        <button
          type="button"
          className="focus:outline-none w-full"
          onClick={onToggle}
        >
          {ctaButtonInnerComponent}
        </button>
      )}
    </div>
  );
};

CTAButton.defaultProps = {
  fixedHeight: undefined,
  link: undefined,
  text: undefined,
  className: undefined,
  onToggle: undefined,
};

export default LinkComponent;
