import { RefObject } from 'react';

export const truncate = (input: string, chars: number): string => {
  if (input.length > chars) {
    return `${input.substring(0, chars)}...`;
  }
  return input;
};

export const isSSR = !(
  // eslint-disable-next-line no-undef
  (typeof window !== 'undefined' && window.document?.createElement)
);

export const getRefElement = <T>(
  element?: RefObject<Element> | T
): Element | T | undefined | null => {
  if (element && 'current' in element) {
    return element.current;
  }

  return element;
};

export const getBaseBgColor = (): string =>
  process.env.GATSBY_DOMAIN_ID === 'rlp' ? 'bg-rlp-green' : 'bg-usm-dark-red';

export const getBaseTextColor = (isHover?: boolean): string => {
  if (isHover) {
    return process.env.GATSBY_DOMAIN_ID === 'rlp'
      ? 'hover:text-rlp-green'
      : 'hover:text-usm-dark-red';
  }
  return process.env.GATSBY_DOMAIN_ID === 'rlp'
    ? 'text-rlp-green'
    : 'text-usm-dark-red';
};

export const numberWithCommas = (x: number): string =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export default {
  truncate,
  isSSR,
  getRefElement,
  getBaseBgColor,
  getBaseTextColor,
  numberWithCommas,
};
