import { useState } from 'react';

type MailingListFormState = {
  email: string;
  onEmailChange: (newValue: string) => void;
  onSubmit: () => void;
  loading: boolean;
  success: boolean;
  error?: string;
};

const useMailingListForm = (): MailingListFormState => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    setError(undefined);
    setSuccess(false);

    if (email === '') {
      setError(`Email is a required field`);
      return;
    }

    setLoading(true);

    const query = `mutation MailingList($email: String!) {
          mailingList(email: $email) {
            email
            rowId
          }
        }`;
    const res = await fetch('/api/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {
          email,
        },
      }),
    });

    const body = await res.json();

    if (body.data?.mailingList?.email === email) {
      console.log('email signup successful');
      setSuccess(true);
      setEmail('');
    } else {
      setError('Unable to sign you up for mailing list. Please contact us.');
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  return {
    email,
    onEmailChange: setEmail,
    onSubmit,
    loading,
    success,
    error,
  };
};

export default useMailingListForm;
