import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';

import useMailingListForm from './useMailingListForm';
import LogoImage from '../logoImage';
import LinkComponent, { ButtonLoading } from '../linkComponent';
import { Menu } from '../menus/types';
import { Facebook, Instagram, Twitter } from '../../assets/vectors/socialIcons';

type FooterProps = {
  FooterMenu: Menu;
};

const Footer: React.FC<FooterProps> = ({ FooterMenu }: FooterProps) => {
  const {
    email,
    onEmailChange,
    onSubmit,
    success,
    error,
    loading,
  } = useMailingListForm();
  return (
    <footer className="md:border-t mt:border-gray-800 py-8 mt-8">
      <div className="container mx-auto md:flex px-4">
        <div className="md:flex-1 md:w-1/2">
          <LogoImage color="black" />
          <div className="md:pr-4">
            <form>
              <div className="lg:flex mt-4">
                <input
                  placeholder="solidarity@gmail.com"
                  value={email}
                  required
                  onChange={(e) => onEmailChange(e.target.value)}
                  className="input-component focus:outline-none px-2 py-1 border-2 border-black w-full placeholder-black focus:placeholder-transparent focus:border-usm-dark-red"
                />
                <div
                  className="bg-usm-dark-red px-4 text-white lg:ml-3 mt-2 lg:mt-0 h-8 lg:h-auto"
                  style={{ minWidth: 240 }}
                >
                  <button
                    type="button"
                    className="focus:outline-none w-full h-full relative"
                    onClick={onSubmit}
                  >
                    {loading && <ButtonLoading small />}
                    <div className={loading ? 'opacity-0' : ''}>
                      Signup for Newsletter
                    </div>
                  </button>
                </div>
              </div>
              {error && (
                <div className="mt-4 text-xs text-usm-dark-red">{error}</div>
              )}
              {success && (
                <div className="mt-4 text-xs">
                  Successfully signed up for USM mailing list.
                </div>
              )}
            </form>
          </div>
        </div>
        <div className="md:flex-1 md:w-1/2 mt-4 md:mt-0 md:pl-4">
          <ul className="uppercase text-xs" style={{ columns: 2 }}>
            {FooterMenu.items?.map((menuItem) => {
              if (!menuItem || !menuItem.link) {
                return null;
              }
              return (
                <li className="mb-2" key={menuItem.id}>
                  <GatsbyLink
                    to={menuItem.link}
                    className="text-black hover:text-usm-dark-red"
                  >
                    {menuItem.text}
                  </GatsbyLink>
                </li>
              );
            })}
            <li className="mb-2">
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:info@uhurusolidarity.org"
                className="text-black hover:text-usm-dark-red"
              >
                Contact us
              </a>
            </li>
            <li>
              <div className="flex mt-2">
                <LinkComponent
                  link="https://www.facebook.com/uhurusolidarity"
                  className="mr-3"
                >
                  <Facebook />
                </LinkComponent>
                <LinkComponent
                  link="https://twitter.com/UhuruSolidarity"
                  className="mr-3"
                >
                  <Twitter />
                </LinkComponent>
                <LinkComponent
                  link="https://www.instagram.com/uhurusolidarity/"
                  className="mr-3"
                >
                  <Instagram />
                </LinkComponent>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
